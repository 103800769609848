
.ui-carousel{
  width: 100%;
  .slick-track
  {
    display: flex !important;
  }

  .slick-slide
  {
    height: inherit !important;
  }
}

