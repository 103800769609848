@import "../../helpers/style/variables";
@import "../../helpers/style/display-breakpoints";


.flagMorocco{
width: 6rem;
border-radius: 50px;
}

.orders{
  margin-bottom: 1rem;
  padding: 2rem 0 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  @include for-phone-only{
    display: block;
    padding: 2rem 0 2rem;
  }
}

.home-hadj-button{
  background: linear-gradient(90deg, rgba(238,200,98,1) 22%, rgba(255,255,255,1) 70%);  
  font-weight: 500;
  font-size: 1.7rem;
  padding: .5rem;
  margin-left:1rem;
  border: 1px solid grey;
  opacity: .8 !important;
  color: gray !important;
  cursor: pointer;
  &:hover{
    color: black !important;
    opacity: 1 !important;
  }
  @include for-phone-only{
    margin-top: 1rem;
  }
}