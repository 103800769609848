@import "../../helpers/style/display-breakpoints";

.contact-section2{
  max-width: 90rem;
  width: 100%;
  display: flex;
  padding: 2rem 1rem;
  .contact-form-button{
    margin: .5rem 0 0 0;
  }
  @include for-tablet-portrait-down{
    flex-direction: column;
    padding: 0 1rem;
  }
  margin: auto;
  .section2-item{
    @include for-tablet-portrait-down{
      flex: 0 0 100%;
      padding-right: 0;

    }
    flex: 0 0 50%;
    padding-right: 1rem;
  }
  .contact-map{
    position: relative;
    @include for-tablet-portrait-down{
      flex: 0 0 100%;
      margin: 1rem 0;
    }
    flex: 0 0 50%;
    align-self: flex-end;
    width: 100%;
    height: 500px;
    .pin-icon{
      font-size: 10px;
    }
  }
}
