.fcu-text{
  margin: 0;
  padding: 0;
}

.fcu-banner-title{
  font-weight: 700;
  font-size: 4rem;
  text-transform: uppercase;
}

.fcu-modal-title{
  font-weight: 700;
  font-size: 2.6rem;
}

.fcu-banner-description{
  font-weight: 500;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-style: italic
}

.fcu-footer-title{
  font-weight: 900;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-style: italic
}

.fcu-header-title{
  font-weight: 600;
  font-size: 2.2rem;
  text-transform: uppercase;
}

.fcu-title{
  font-weight: 900;
  font-size: 4rem;
  text-transform: uppercase;
}

.fcu-paragraph-title{
  font-weight: 600;
  font-size: 2rem;
}

.fcu-paragraph-sub-title{
  font-weight: 600;
  font-size: 1.5rem;
}

.fcu-paragraph-bold{
  font-weight: 500;
  font-size: 1.25rem;
}

.fcu-paragraph{
  font-weight: 400;
  font-size: 1.25rem;
}

.fcu-paragraph-description{
  font-weight: 200;
  font-size: 1.25rem;
}