@import "../../helpers/style/display-breakpoints";

.about-section-container{
  width: 100%;
  text-align: justify;
  .about-section{
    max-width: 90rem;
    width: 100%;
    margin: auto;
    padding: 5rem 0;
    display: flex;
    justify-content: space-between;

    @include for-tablet-portrait-down{
      align-items: center;
      flex-direction: column;
      padding: 5rem 1rem;
    }
    .section-about{
      @include for-tablet-portrait-down{
        flex: 1 0 100%;
      }
      flex: 1 0 60%;
      padding: 0 2rem;
      margin-bottom: 1rem;
    }
    .section-image{
      width: 40%;
      flex: 1 0 40%;
      @include for-tablet-portrait-down{
        width: 100%;
        flex: 1 0 100%;
        margin: 0;
        padding: 2rem 0;
      }
    }
  }
}
