@import "../../helpers/style/variables";
@import "../../helpers/style/display-breakpoints.scss";

.payment-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;
  margin-right: 2rem;
  margin-left: 2rem;

  padding: 1rem;
  background-color: darken(white,2%);

  .circleIcon{
    font-size: 9rem;

    &.green{
      color: lighten(green,10%);
    }

    &.orange{
      color: lighten(orange,10%);
    }
  }
}

.admin-container {
  margin: 8rem auto 4rem;
  max-width: 90rem;
  padding: 0 1rem;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary;
  }
  .ant-tabs-ink-bar {
    background: $primary;
  }
  .ant-tabs-tab:hover {
    &:hover {
      color: $primary !important;
    }
  }

  .adminTabs {
    @include for-tablet-portrait-down() {
      .ant-tabs-nav {
        display: block;
      }
      .ant-tabs-nav-wrap {
        display: block;
        .ant-tabs-nav-list {
          display: block;
          .ant-tabs-tab {
            display: block;
          }
        }
      }
    }
  }
}
