@import "../../../helpers/style/variables";

.fcu-ui-button{
  display: inline-block;
  padding: 8px 20px;
  font-weight: 400;
  cursor: pointer;
  color: $grey-color;
  text-transform: uppercase;
  border: 0.2rem solid $grey-color;
  &:hover{
    background-color: $primary;
    color: $grey-color;
    //border-color: $primary;
  }

}
.full-width{
  width: 100%;
  text-align: center;
}

.fcu-ui-white{
  color: $white !important;
  border: 0.2rem solid $white
}
.fcu-ui-purple{
  color: $white;
  background-color: #F1C965;
  border: 0.2rem solid #F1C965;
  &:hover{
    background-color: transparent;
    color: #F1C965;
  }
}
