.invoiceCont{
    padding: 4rem;

    .paymentBtn{
        width: 100%;
        height: 4rem;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}