@import '~antd/dist/antd.css'; // Antd framework
@import "helpers/style/normalize";
@import "helpers/style/display-breakpoints";
@import "helpers/style/variables";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Barlow Semi Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

html{
  min-height: 100%;
}

html,
body {
  @include for-phone-only {
    font-size: 11px;
  }
  @include for-tablet-portrait-up {
    font-size: 12px;
  }
  @include for-tablet-landscape-up {
    font-size: 13px;
  }
  @include for-desktop-up {
    //font-size: 16px;
    font-size: 14px;
  }
  @include for-big-desktop-up {
    font-size: 16px;
  }
  @include for-desktop-height-small {
    font-size: 11px;
  }
}


.center-space-between{
  display: flex;
  justify-content: space-between;
}

@include for-tablet-portrait-down {
  .mobile-vertical{
    flex-direction: column
  }
}


a{
  color: #000;
  &:hover{
    color: $primary;
  }
}

.back-to-top{
  border: 2px solid $white;
  color: $white;
  padding: 1rem;
  font-size: 20px;
  -webkit-transform: translateZ(0);
  transition: all .6s;
  bottom: 35px;
  background-color: $primary;
  &:hover{
    opacity: .6;
  }
}

.modal{
  position: relative;
  width: 100%;
  z-index: 10;
  .ant-modal-content{
    z-index: 5;
  }
  .modal-footer{
    background-color: #EEC862;
    width: 95%;
    height: .75rem;
    position: absolute;
    border-bottom: 1px solid black;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -.75rem;
    z-index: 10;
    -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
    .modal{

      .modal-footer{
        bottom: -1.5rem;
      }
    }
  }
}

