@import "../../helpers/style/display-breakpoints";

.home-info{
  max-width: 90rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0 ;
  @include for-tablet-portrait-down{
    padding: 2rem 1rem;
  }
  .home-info-item{
    flex: 0 0 25%;
    @include for-tablet-portrait-down{
      flex: 0 0 50%;
    }
    @include for-phone-only{
      flex: 0 0 100%;
    }
    text-align: center;
    .home-info-icon{
      font-size: 3.5rem;
    }
  }

}