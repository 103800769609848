@import '../../helpers/style/variables';

.widget-whatsapp{
  position:fixed;
  bottom:42.5px;
  right:45px;
  z-index:100;
  outline: none;
  border: 2px solid #fff;
  padding: 1rem;
  border-radius: 50%;
  background-color:#25d366;
  color:#FFF;
  text-align:center;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-whatsapp-chat {
  max-height: 32rem;
  min-height: 15rem;
  box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
  position: fixed;
  bottom: 8rem;
  right:45px;
  z-index: 100;
  margin: 1rem 0 0;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
  .header{
    text-align: center;
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    .headerContent{
      display: inline-block;
      &.close{
        display: inline-flex;
        align-items: center;
        margin-left: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

  }
  .messages{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    .message{
      min-width: 80%;
      margin-right: 25%;
      background-color: #eee;
      position: relative;
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  .input{
    display: inline-block;
    padding: 1rem;
    .ant-form-item{
      margin: 0;
    }
    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus{
      background: $primary;
      border-color: $primary;

    }
  }
}
