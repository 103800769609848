@import "../../../helpers/style/display-breakpoints";
@import "../../../helpers/style/variables";


.article-buttons{
  display: flex;
  margin: 1rem 0;
  .article-button{
    margin: 0 .5rem
  }
  @include for-tablet-portrait-down{
    flex-direction: column;
    width: 100%;
    .article-button{
      margin: .5rem 0;
      width: 100%;
    }
  }
}
.new-kennis-editor{
  .wrapperClassName{
    background-color: #e9e9e9
  }
}