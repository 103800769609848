@import "../../helpers/style/display-breakpoints";
@import "../../helpers/style/variables";

.about-team{
  text-align: center;
  padding: 5rem 0;
  .about-team-selection{
    margin: 1rem 0;
    .about-team-button{
      margin: 0 .25rem;

    }
    .selected{
      border: $primary 0.2rem solid;
      background-color: $primary;
      color: $white;
    }
  }
  .about-team_members{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90rem;
    margin: auto;
    .team_members-item{
      flex: 0 0 25%;
      @include for-tablet-portrait-down{
        flex: 0 0 40%;
      }
      border: 1px solid $grey-color;
      padding: 1rem;
      margin: .75rem;
      .item-icon{
        margin: 1rem 0;
        width: 4rem;
        height: 4rem;
      }
      .item-about{
        text-align: justify;
        margin: 0 0 1rem;
        font-size: 1rem;
      }
    }
  }
  }
