@import "../../../helpers/style/display-breakpoints";
@import "../../../helpers/style/variables";

.product {
  position: relative;
  padding: 0.5rem;
  background-color: lighten(#d3d3d3, 12%);
  margin-top: 0.5rem;
  .delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: red;
    cursor: pointer;
  }
}

.ant-collapse-extra {
  @include for-phone-only() {
    .ant-space {
      display: block;
      .ant-space-item {
        margin-bottom: 0.5rem;
      }
    }
  }
}
.ant-collapse-header {
  @include for-phone-only() {
    display: block !important;
  }
}

.ant-table-cell {
  @include for-phone-only() {
    display: block;
  }
}
