@import "../../helpers/style/display-breakpoints";

.infoModal{

    .tableCont{
        margin-top: 1.5rem;
    }
    .title{
        font-weight: 600;
        font-size: 1.8rem;
        text-transform: uppercase;
    }
    .priceTable{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    width: 80%  !important;
    max-width: 1500px !important;
    @include for-phone-only{
        width: 95% !important;
    }

    .teared-main{
        padding-bottom: .1rem;
        box-shadow: 7px 26px 20px 0px rgba(0,0,0,0.2);
    }

    .ant-modal-title{
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
    }

    .modalBody{
        padding: 0 4rem 4rem 4rem;
        @include for-phone-only{
            padding: 2rem;
        }

        .startEndDetails{
            @include for-phone-only{
                display: block;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F8F8F8;
            margin-bottom: 1.5rem;
            .start{
                padding: 1rem;
                font-size: 1.2rem;
            }
            .end{
                padding: 1rem;
                font-size: 1.2rem;
            }
            .title{
                padding: 1rem;
                font-size: 1.2rem;
                font-weight: 500;
                font-style: italic;
            }
        }
    }

    .ant-modal-body{
        padding:0;
        .line{
            border: 1px solid #EEC862;
            opacity: .3;
        }
        .infoSection{
            padding: .5rem;

            .why-us{
                .title{
                    font-weight: 600;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                }
           
                .textContent{
                    font-size: 1.1rem;
                }
            }
        }
        .hotelsContainer{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            
            @include for-phone-only{
                display: block;
            }
            .hotelContainer{
                flex: 50%;
                margin-bottom: 1rem;
                padding-right: 1rem;
                .hotelDesc{
                    font-size: 1.1rem;
                    margin-top: .4rem;
                    font-style:italic;
                }
                .hotelTitle{
                    display: flex;
                    align-items: center;
                    @include for-phone-only{
                        display: block;
                    }
                    @include for-small-laptop{
                        display: block;
                    }

                    .hotelLogo{
                        height: 4rem;
                        width: 15rem;
                        @include for-phone-only{
                            margin-bottom: 1rem;
                        }
                        @include for-small-laptop{
                            margin-bottom: 1rem;
                        }
                    }
                    
                    .hotelRating{
                        margin-left: 1rem;
                        .hotelRatingStar{
                            font-size: 2.5rem;
                            color: #EEC862;
                        }
                    }
                    .hotelDistanceContainer{
                        display: flex;
                        align-items: center;
                        padding: 0 2rem 0 2rem;
                        @include for-phone-only{
                            padding: 0;
                            margin-bottom: .5rem;
                        }
                        @include for-small-laptop{
                            padding: 0;
                            margin-bottom: .5rem;
                        }

                        .hotelDistanceIcon{
                            display: flex;
                            align-items: center;
                            width: 3rem;
                            height: 3rem;
                        }
                        .hotelDistanceLabel{
                            margin-left: .2rem;
                            font-size: 1.2rem;
                            font-style: italic;
                        }
                    }
                }
             
            }
         
        }

        .infoBulletPoints{
            display:flex;
            flex-wrap: wrap;
            .bulletpoint{
                flex: 50%;
                margin-bottom: .5rem;
                .pointTitle{
                    align-items: center;
                    display: flex;
                    font-size: 1.4rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    .products-item-list-icon{
                        color: #EEC862;
                        margin-right: 1rem;
                    }
                }
                .pointDesc{
                    font-size: 1.2rem;
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                }
            }
        }
   
        .imgContainer{
            position: relative;
            .hotelImg{
                width: 100%;
                height: 30rem;
             }
             .imgLoc{
                 background: rgba(0, 0, 0, 0.4);
                 opacity: .8;
                 padding: 1.5rem;
                 color: white;
                 font-weight: 550;
                 font-style: italic;
                 font-size: 1.2rem;
                 position:absolute;
                 right: .5rem;
                 top: .5rem;
             }
             .carouselArrow{
                background: rgba(0, 0, 0, 0.4);
                opacity: .7;
                padding: 1rem;
                color: white;
                font-size: 2rem;
                position: absolute;
                top: 50%;
                &.right{
                    right:.5rem;
                }
                &.left{
                    left:.5rem;
                }
             }
         }
    }


    .infoCarousel{
       opacity: .9;
       margin-bottom:2rem;
    }

   
}