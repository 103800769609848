.loadingCont{
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
    padding: 2rem 0;

    .loadingIcon{
        color: #eec862;
        font-size: 8rem;
    }
}