@import "../../helpers/style/variables";

.account-container{
  margin: 8rem auto 4rem;
  max-width: 90rem;
  padding: 0 1rem;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: $primary
  }
  .ant-tabs-ink-bar{
    background: $primary;
  }
  .ant-tabs-tab:hover{
    &:hover, &:focus{
      color:$primary !important;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    //display: flex;
  }
}