@import '../../helpers/style/display-breakpoints';
@import '../../helpers/style/variables';

.shopping-cart-container{
  margin: 4rem auto;
  padding: 4rem 0;
  max-width: 90rem;
  .shopping-cart-layout{
    display: flex;
    flex-direction: column;
    .shopping-cart-table{
      flex: 1 0 100%;
      padding: .9rem;
    }
  }
}
.details{
  @include for-tablet-portrait-down{
    flex-direction: column;
  }
}

.test{
  color: red;
}