.resetCont{
    padding-top: 4rem;
    padding-left: 9rem;
    padding-right: 9rem;
    .form{
        background-color: darken(white,3%);
        padding: 2rem;
        border-radius: .5rem;

        .tokenInput{
            display: none;
        }
    }
}