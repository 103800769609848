$white: #FFF;
$black: #000;

$primary: #EEC862;
$purple: #BB62EE;

$text-color: #333;
$grey-color: #707070;
$gray-color: #A5A5A5;

$overlay: rgba(0,0,0,.45);
$overlay2: rgba(0,0,0,.60);
