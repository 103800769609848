@import "../../../helpers/style/variables";

.orange{
  &:before{
    background-image: url("../../../assets/icons/tear_top_orange.svg");
  }
  &:after {
    background-image: url("../../../assets/icons/tear_bottom_orange.svg");
  }
}
.white{
  &:before{
    background-image: url("../../../assets/icons/tear_top.svg");
  }
  &:after {
    background-image: url("../../../assets/icons/tear_bottom.svg");
  }
}
.border{
  &:before{
    background-image: url("../../../assets/icons/tear_top_border.svg");
  }
}


.ui-teared{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    height: 20px;
    width: 100%;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    height: 20px;
    width: 100%;
  }
}
.triangle-white{
  &:after{
    background-image: url("../../../assets/icons/arabia_tear.svg");
  }
}

.triangle-black{
  &:after{
    background-image: url("../../../assets/icons/arabia_tear_black.svg");
  }
}
.triangle-gradient{
  &:after{
    background-image: url("../../../assets/icons/arabia_tear_gradient.svg");
  }
}

.ui-teared-triangle{
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-bottom: 7rem;
  &:after{
    content: '';
    position: absolute;
    left: -5px;
    right: 0;
    bottom: -5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 102%;
  }
}