
.cancelWarningCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    .cancelWarningIcon{
        font-size: 6rem;
        margin-bottom: 1rem;
        color: orange;
    }
    p{
        font-size: 1.2rem;
    }
}

.disIcon{
    margin-left: .5rem;
}