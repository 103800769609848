@import "../../helpers/style/display-breakpoints";

.loginDiv{
    margin-top: 8rem;
    margin-left: 10rem;
    margin-right: 10rem;
    @include for-tablet-portrait-down{
        margin-left: 1rem;
        margin-right: 1rem;
    }
}