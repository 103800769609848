@import '../../helpers/style/variables';

.zol-ui-collapseable{
    margin: 0 .5rem 60px;

    .zol-ui-collapseable-header{
        display: flex;
        width: 100%;
        align-items: flex-start;
        text-align: left;
        flex-direction: column;
        justify-content: flex-start;
        color: grey;
        margin-bottom: 20px;

        p {
            margin: 0;
            padding: 0 .5rem;
            text-transform: uppercase;
        }

        .zol-ui-collapseable-toggle{
            cursor: pointer;
            display: flex;
            justify-content: center;
        }

        .zol-ui-collapseable-line{
            width: 100%;
        }
    }
}