@import "../../helpers/style/variables";
@import "../../helpers/style/display-breakpoints";

.arabia-products-container{
  .arabia-products{
    max-width: 90rem;
    width: 100%;
    margin: auto;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .arabia-products-label{
      text-align: center;
      text-transform: none;
      font-weight: 700;
    }
    .arabia-products-line{
      width: 7rem;
      border: $purple 2px solid;
      background-color: $purple;
    }
    .arabia-products-description{
      text-align: justify;
      max-width: 65rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
  .arabia-products-layout{
    max-width: 90rem;
    width: 100%;
    margin: auto;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include for-tablet-portrait-down{
      flex-direction: column;
    }

    .arabia-products-item-container{
      color:$white;
      width: 100%;
      margin: 0 .5rem;


      //border: 1px solid black;
      padding: .5rem;
      .arabia-products-item{
        background-image: linear-gradient(to right, $purple, $primary);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        .arabia-product-text{
          padding: .5rem;
        }
        .arabia-product-line{
          border: 1px solid $white;
        }
        .arabia-products-item-image{
          width: 100%;
        }
        .arabia-products-item-desc{
          min-height: 8rem;
          display: flex;
          align-items: center;
          padding: .5rem;
          justify-content: center;
        }
      }
      .arabia-product-button{
        width: 100%;
        margin: .5rem 0;
      }
    }
  }
  .arabia-meeting{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .arabia-meeting-label{
      line-height: 1;
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: center;
      z-index: 1;
    }
    .arabia-meeting-button{
      z-index: 1;
    }
  }
}
