@import "../../helpers/style/display-breakpoints";
@import "../../helpers/style/variables";

.arabia-banner-container{
  position: relative;
  height: 45rem;
  @include for-tablet-portrait-down{
    height: 35rem;
  }
  background-image:  linear-gradient(to right, 	rgba(51,51,51,.75), rgba(169,169,169,.75)), url("../../assets/images/arabia_teaching.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .arabia-banner{
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 90rem;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-down{
      height: 35rem;
    }
    top: 0;
    color: $white;
    .arabia-banner-label{
      line-height: 3.25rem;
      max-width: 50rem;
      text-align: justify;
      @include for-tablet-portrait-down{
        text-align: center;
      }
    }
    .arabia-banner-description{
      line-height: 1.5rem;
      margin: 1rem 0;
      max-width: 50rem;
      text-align: justify;
      @include for-tablet-portrait-down{
        text-align: center;
      }
    }
    .arabia-banner-button{
      margin: .5rem 0;

      border-width: .3rem;
      font-weight: 500;
      padding: .5rem 5rem;
    }
    .secondPart{
      display: inline-block;
      color: #F1C965;
    }

  }

  .fcu-ui-button{
    z-index: 9999;
    display: inline-block;
    padding: 8px 20px;
    font-weight: 400;
    cursor: pointer;
    color: $grey-color;
    text-transform: uppercase;
    border: 0.2rem solid $grey-color;
    &:hover{
      background-color: $primary;
      color: $grey-color;
      //border-color: $primary;
    }
  
  }
  .full-width{
    width: 100%;
    text-align: center;
  }
  
  .fcu-ui-white{
    color: $white !important;
    border: 0.2rem solid $white
  }
  .fcu-ui-purple{
    color: $white;
    background-color: #F1C965;
    border: 0.2rem solid #F1C965;
    &:hover{
      background-color: transparent;
      color: #F1C965;
    }
  }
}