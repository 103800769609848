.ant-modal{
    width: 80% !important;
}

.ant-radio-button-wrapper{
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.infoBulletPoints{
    display:flex;
    flex-wrap: wrap;
    .bulletpoint{
        flex: 50%;
        margin-bottom: .5rem;
        .pointTitle{
            align-items: center;
            display: flex;
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            .products-item-list-icon{
                color: #EEC862;
                margin-right: 1rem;
            }
        }
        .pointDesc{
            font-size: 1.2rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
}