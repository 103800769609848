@import "../../../helpers/style/display-breakpoints.scss";

.adminOrderss {
  .thumbs-wrapper{
    display: none;
  }
  .photoCarouselCont{
    width: 29.5rem;
    background-color: #000;
    .carousel-status{
      font-weight: 700;
      font-size: 1rem;
      right: 1rem;
      top: .75rem;
    }
    .photoCarousel{
      position: relative;
    
      h3{
        border-radius: .5rem;
        font-size: 1rem;
        position: absolute;
        top: .75rem;
        left: 1rem;
        background: white;
        padding: .5rem;
      }
      .carImg{
        height: 17rem !important;
        width: auto !important;
      }
    }
  }



  .actionIcon {
    font-size: 1.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .optionsCont {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    box-sizing: border-box;
    width: 100%;

    // flex-direction: column;
    .optionCont {
      display: flex;
      position: relative;
      align-items: center;

      padding: 2rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      font-size: 1.3rem;
      background-color: darken(white, 5%);
      width: 32.1%;

      @include for-tablet-portrait-down() {
        width: 45%;
      }

      @include for-phone-only {
        width: 100%;
      }
      font-weight: 700;
      border-radius: 0.25rem;
      height: 8rem;
      cursor: pointer;
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
      .check {
        position: absolute;
        bottom: 0;
        right: 1rem;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 1.2rem;
        color: white;
        border: 2px solid white;
        border-radius: 0.25rem;
        padding: 0.5rem;
        text-transform: uppercase;

        &:hover {
          color: #ffd700;
          border: 2px solid #ffd700;
        }
      }
      .label {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
    }
  }

  .inputCont {
    margin-bottom: 1rem;
    width: 100%;
  }

  .catDetails {
    .back {
      cursor: pointer;
      text-transform: uppercase;
    }

    .titleCont {
      display: flex;
      align-items: center;
      .fcu-ui-button{
        margin-right: 1rem;
      }
      @include for-phone-only {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
      }
      h1 {
        @include for-phone-only {
          width: 100%;
        }
        margin-right: 1rem;
      }
    }

    .itemsCont {
      .filterCont {
        margin-bottom: 1rem;
        .searchCont {
          display: flex;

          .inputSearch {
            height: 100%;
            width: 28rem;
            margin-right: 1rem;
          }
        }
      }
      .cardDetails {
        display: flex;

        @include for-tablet-portrait-down() {
          display: block;
        }
        // width: 50%;
        .firstColumn {
          margin-right: 2rem;
        }
        .secondColumn {
          margin-right: 2rem;
        }
      }
      .cardCont {
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 1rem;
        background-color: darken(white, 2%);

        @include for-tablet-portrait-down() {
          display: block;
          height: 100%;
        }

        .totalPaid {
          &.orange {
            button {
              font-weight: 550;
              border: 1px solid orange;
              background-color: lighten(orange, 48%);
            }
          }
          button {
            font-weight: 550;
            border: 1px solid darkgreen;
            background-color: lighten(darkgreen, 78%);
          }
        }
        h2 {
          margin: 0;
          padding: 0;
        }

        .ant-card-cover {
          height: 100%;
          img {
            height: 100%;
            width: 29.5rem;

            @include for-desktop-only(){
              width: 31rem;
            }
          }
        }
        .ant-card-body{
          h2{
            font-size: 1.6rem;
          }
        }

        .ant-card-actions {
          display: block;
          margin-left: auto;
          font-size: 2rem;
          height: 100%;

          @include for-tablet-portrait-down() {
            display: flex;
            justify-content: space-between;
          }
          li {
            width: 100% !important;
            text-align: center;
          }
          .anticon {
            font-size: 1.3rem;
            margin-right: 1.5rem;
            margin-top: 0.5rem;
          }
        }

        .cardContentItem {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
          font-size: 1rem;
          justify-content: space-between;
          @include for-tablet-portrait-down() {
            width: 100%;
          }

          .cardContTitle {
            font-weight: 600;
            margin-right: 4rem;
            font-size: 1.2rem;
            span{
              font-size: 1.2rem;
            }

            @include for-tablet-portrait-down() {
              font-size: 1.3rem;
              span{

              }
            }
          }
          .cardContContent {
            font-size: 1.2rem;

            .ant-btn{
              // padding: 1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
            }
            @include for-tablet-portrait-down() {
              font-size: 1.3rem;
            }
            &.red {
              color: red;
            }
          }
        }
      }
    }
  }
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .statistic {
    padding: 1rem;
    border-radius: 0.25rem;
    width: 32%;
    background-color: darken(white, 2%);
    margin-right: 1rem;
    margin-bottom: 1rem;

    @include for-tablet-portrait-down() {
      width: 100%;
    }
  }
}

.listPopup{
  .fcu-ui-button{
    margin-top: 1rem;
  }
}

.visumPopup{
  .sumCont{
    display: flex;
  }
  .firstColumn{
    width: 30%;
    margin-right: 1rem;
    .buttonBelow{
      margin-top: 1rem;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  .secondColumn{
    width: 70%;
    .contentCont{
      margin-bottom: 1rem;
      width: 100%;
    }
  }


}

.remindVisumPopup{
  .select{
    margin-bottom: 1rem;
  }

  .logSelect{
   // margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .remindText{
    font-size: 1.2rem;
    &.logs{
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
  }
  .ant-checkbox-wrapper{
    font-size: 1.2rem;
    background-color: darken(white, 4%);
    margin: .5rem;
    padding: .5rem;
  }
  .remindButton{
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

.fcu-ui-button{
  &:disabled{
    opacity: .5;
  }
}

.roomListPopup{
  position: relative;
  width: 95% !important;
}