@import "../../helpers/style/variables";
@import "../../helpers/style/display-breakpoints";

.footer{
  width: 100%;
  .footer-top-container{
    max-width: 90rem;
    margin: 0 auto;
    .footer-top{
      margin: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include for-tablet-portrait-down{
        flex-direction: column;
      }
      .item{
        display: flex;
        align-items: center;
        color: $text-color;
        &:hover{
          color: $primary;
          cursor: pointer;
        }
        .item-icon{
          font-size: 2.25rem;
          margin-right: .5rem;
        }
      }
    }
  }
  .footer-main-container{

    background-color: #000;

   .footer-main{
     padding: 4rem 0 2rem;
     max-width: 90rem;
     margin: auto;
     color: #fff;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     text-align: center;
     width: 100%;
     .footer-label{
       width: 100%;
       text-align: center;

     }
     .contacts{
       margin: 1rem 0;
       width: 100%;
       display: flex;
       justify-content: space-evenly;
       @include for-tablet-portrait-down{
         flex-direction: column;
         align-items: center;
       }
       .contact-item{
         width: 15rem;
         color: #fff;
         .contact-item-icon{
           font-size: 4rem;
         }
         .contact-item-value{
           //margin: 1rem 0;
         }

       }
     }
   }
  }
  .footer-terms-container{
    color: #fff;
    padding: 1rem 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    text-align: center;
    .splitter{
      margin: 0 .5rem;
    }
    span{
      cursor: default;

      &:hover{
        color: $primary;
      }
    }
    a{
      color: #fff;
      &:hover{
        color: $primary;
      }
    }
  }
}