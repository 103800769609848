@import "../../../helpers/style/display-breakpoints.scss";

.versHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-tablet-portrait-down {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.delButton{
  background-color: red;
  padding: .3rem .5rem;
  border-radius: .25rem;
  font-size: 1rem;
  cursor: pointer;
}

.initalRoomsCont {
  display: flex;
  flex-wrap: wrap;

  @include for-tablet-portrait-down {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .initialRoom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include for-tablet-portrait-down {
      width: 100%;
    }
    width: 48%;
    margin-right: 1%;
    background-color: darken(white, 5%);
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-bottom: 1rem;

    .actionCont {
      display: flex;

      @include for-tablet-portrait-down {
        flex-direction: column;
      }
      .btn {
        margin-right: 0.5rem;
        .buttonIcon {
          margin-right: 0.2rem;
          font-size: 1rem;
        }
      }
    }

    h2 {
      display: flex;
      align-items: center;
      margin: 0;
      span {
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 1.4rem;
        font-style: italic;
      }
    }
  }
}

.headerCont {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-tablet-portrait-down {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .addRoom {
    width: 40%;

    @include for-tablet-portrait-down {
      width: 100%;
      margin-bottom: 1rem;
    }
    .ant-form {
      display: flex;
      justify-content: space-between;
    }
    .ant-form-item {
      width: 100%;
      margin: 0;
      margin-right: 0.5rem;
    }
  }

  .leftButton {
    margin-right: 1rem;
    @include for-tablet-portrait-down {
      margin-bottom: 1rem;
    }
  }
}

.hide {
  display: none;
}

.roomsListCont {
  z-index: 999;

  .counts {
    display: flex;
    flex-wrap: wrap;
    h2 {
      width: 100%;
      margin-bottom: 1rem;
    }
    h3 {
      text-align: center;
    }
    .count {
      padding: 1rem;
      width: 30%;

      margin-right: 1%;
      background-color: darken(white, 2%);
    }
  }
}

.roomsContainer {
  display: flex;
  flex-wrap: wrap;


  @include for-tablet-portrait-down {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  margin-bottom: 2rem;
  ul {
    padding: 0;
    width: 32%;
    margin-right: 1%;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .dragCont{
    &.disabled{
      background-color: darken(lightgray, 10%) !important;
    }
  }
  .roomNumberHotel {
    background-color: darken(rgb(238, 200, 98), 10%);
    padding: 0.2rem 0.5rem;
    font-style: italic;
    font-weight: 500;
  }
  .dragCont {
    width: 33% !important;
    flex-direction: column;

    @include for-tablet-portrait-down {
      width: 100% !important;
    }

    .headerRoom {
      @include for-tablet-portrait-down {
        flex-direction: column;
      }

      .headerText{
        font-size: .95rem;
      }

      background-color: black;
      color: white;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .roomNumber {
        padding: 0.5rem;
        background-color: lighten(black, 30%);
        margin-right: 1rem;
      }

      .btnContainer {
        @include for-tablet-portrait-down {
          margin-top: 1rem;
          .roomSelector {
            margin-right: 1.5rem;
          }
        }
      }
    }
    .draggable {
      cursor: pointer;
      list-style-type: none;
      margin: 0;
      display: flex;
      align-items: center;

      padding: 0.5rem 1rem;
      background-color: darken(white, 5%);
    }
  }

  .roomSelector {
    cursor: pointer;
    margin-right: 0.2rem;
    background-color: rgb(238, 200, 98);
    color: black;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  }
}
