@import '../../helpers/style/display-breakpoints';
@import '../../helpers/style/variables';

.fcu-header-container{
  -webkit-box-shadow: 0 10px 20.5px 0 rgba(0, 0, 0,.5);
  -moz-box-shadow:0 10px 20.5px 0 rgba(0, 0, 0,.5);
  box-shadow: 0 10px 20.5px 0 rgba(0, 0, 0,.5);
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
  z-index: 1001;

  .fcu-header{
    max-width: 90rem;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: .5rem 1rem;
    @include for-tablet-portrait-down{
      padding: .5rem 1rem;
    }
    .menu-title{

      white-space: nowrap;
      @include for-phone-only{
        display: none;
      }
    }
    .menu-icon{
     &:first-child{
       margin-left: 1rem;
     }
      font-size: 1.75rem;
      @include for-phone-only{
        font-size: 2.5rem;
      }

    }
    .header-menu{
      color: $text-color;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .ant-popover-inner-content{
        padding: 0;
      }
      .header-menu-clickable{
        cursor: pointer;
        padding: .9rem 1rem;
        &:hover{
          background-color: $primary;
          color: $white;
        }
      }

    }
    .fcu-header-logo{
      height: 4rem;

    }
  }

  .fcu-header-menu-container{
    z-index: 1000;
    max-width: 90rem;
    margin: auto;
    background-color: #fff;
    padding-bottom: 10px;
    .fcu-header-menu{
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      margin: auto;
      .selected{
        background-color: $primary;
        color: $white !important;
      }
      .selected-arabia{
        background-color: #A9A9A9;
        color: $white !important;
      }
      .fcu-header-menu-item{
        color: $text-color;
        text-decoration: none;
        border-top: 1px solid #ccc;
        padding: 10px;

      }
    }
  }
}