@import "../../helpers/style/display-breakpoints";
@import "../../helpers/style/variables";

.home-crowdfunding-container{
  max-width: 90rem;
  color: #fff;
  margin: 0 auto 2rem;
  padding: 2rem 5rem;

  .crowdfunding-title{
    text-align: center;
    margin-bottom: 1rem;
  }
  .crowdfunding-content{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding-right: 2.25rem;
  }
  .home-crowdfunding-carousel{
    display: inline-block;
    width: 50%;
    .home-crowdfund{
      border: .2rem solid $white;
      padding: 1rem;
      height: 100%;
      //height: 220px;
      position: relative;
      //.crowdfund-body{
      //  height: 70%;
      //  overflow: auto;
      //}
      //.crowdfund-footer{
      //  position: absolute;
      //  width: 95%;
      //  left: 1%;
      //  right: 1%;
      //  bottom: 5%;
      //  margin: auto;
      //}
      @include for-tablet-portrait-down{
        margin: .5rem 0 ;
        width: 100%
      }
      .home-crowdfund-progress{
        .ant-progress-text{
          color: #fff;
          font-size: 1em;
          .anticon-check-circle{
            font-size: 1.5em;
          }
        }
        .ant-progress-inner{
          border: 2px solid $white;
        }
      }
      .crowdfund-description{
        font-weight: 500;
        min-height: 3.9rem;
      }
    }
  }
  .crowdfunding-content, .home-crowdfunding-carousel{
    @include for-tablet-portrait-down{
      width: 100%
    }
  }
}
