@import "../../../helpers/style/variables";
@import "../../../helpers/style/display-breakpoints";

.articles-detail-article-container{
  display: flex;
  max-width: 90rem;
  margin: 3rem auto;
  padding: 0 1rem;
  @include for-tablet-portrait-down{
    flex-direction: column;
  }
  .articles-detail-article{
    text-align: justify;
    flex: 0 0 65%;
    margin: 1rem 0;
    padding-right: 2rem;
    @include for-tablet-portrait-down{
     padding-left: 2rem;
    }
    .articles-image{
      object-fit: cover;
      width: 100%;
      height: auto;
      flex: 1 0 55%;
    }
  }
  .articles-detail-see-more{
    flex: 0 0 35%;
  }
}