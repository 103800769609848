.checkout-details-container{
  text-align: center;
  .checkout-details{
    display: flex;
    flex-direction: column;
    .checkout-detail{
      flex: 0 0 50%;
      padding: 2rem 4rem;
    }
  }
}