@import "../../helpers/style/display-breakpoints";
@import "../../helpers/style/variables";


.section1-text{
  padding-right: 1rem;
}
.contact-section1{
  max-width: 90rem;
  width: 100%;
  margin: auto;
  padding: 4rem 1rem;
  display: flex;
  justify-content: space-between;
  .contact-text{
    margin-bottom: 1rem;
  }
  @include for-tablet-landscape-down{
    padding: 4rem 1rem;
  }
  @include for-tablet-portrait-down{
    align-items: center;
    flex-direction: column;

  }
  .section1-c{
    display: flex;
    flex: 0 0 50%;
    @include for-tablet-portrait-down{
      flex: 0 0 100%;
      flex-direction: column;
    }
    .section1-item{
      color: $text-color;
      display: flex;
      height: 11.5rem;
      flex-direction: column;
      align-items: center;
      border: 1px solid $text-color;
      flex: 1 0 30%;
      text-align: center;
      padding: .5rem;
      margin: 0 1rem;
      @include for-tablet-portrait-down{
        flex: 0 0 100%;
        margin: 1rem 0;
      }
      &:first-child, &:last-child{
        margin: 0;
      }
      .item-icon{
        font-size: 3rem;
        margin-bottom: 1rem;
      }
      .section1-label{
        font-weight: bold;
      }
    }
  }
}
