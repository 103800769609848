@import "../../../helpers/style/variables";
@import "../../../helpers/style/display-breakpoints";

.articles-detail-see-more-label{
  margin: 1rem 2rem 0;
}
.articles-detail-see-more{
  display: flex;
  justify-content: center;
  flex-direction: column;
  .articles-item{
    flex: 0 0 45%;
    display: flex;
    justify-content: space-between;
    border: 2px solid $gray-color;
    margin: 0 0 1rem 1rem;
    max-height: 22.5rem;
    @include for-tablet-portrait-down{
      flex: 0 0 100%;
    }
    .articles-about-paragraph{
      max-height: 8rem;
      overflow: hidden;
    }
    .articles-image{
      object-fit: cover;
      width: 50%;
      height: auto;
      flex: 1 0 55%;
    }
    .articles-about{
      padding: 1rem;
      overflow: auto;
    }
  }
}