@import "../../../helpers/style/variables";
@import "../../../helpers/style/display-breakpoints.scss";
.ant-result {
  padding: 1.5rem 1rem;
  .anticon {
    font-size: 100px;
    color: $primary !important;
  }
}

.exclusiveRroom {
  display: none;
  background-color: lighten($primary, 15%);
  padding-left: 0.5rem;
  color: white;
  font-weight: 500;

  .priceExcl {
    margin-left: 0.5rem;
    font-size: 0.85rem;
  }

  &.show {
    display: block;
  }

  .ant-checkbox-wrapper {
    color: lighten(black, 20%);
    text-shadow: #fc0 1px 0 10px;
  }
}

.stepContainer {
  .steps-action {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    .previous {
      width: 40%;
      font-weight: 600;
      background-color: white;
      border-color: black;
      &:hover {
        background-color: $primary;
      }
      &:focus {
        color: black;
      }
    }
    .next {
      width: 40%;
      font-weight: 600;
      background-color: white;
      border-color: black;
      &:hover {
        background-color: $primary;
      }
      &:focus {
        color: black;
      }
    }
  }
  .stepIcon {
    &.active {
      color: white;
      background-color: $primary;
    }
  }
  .ant-steps-item-active {
    .stepIcon {
      color: white;
      background-color: $primary;
    }
    .ant-steps-item-tail::after {
      background-color: $primary;
    }
  }
  .ant-steps-item-finish {
    .stepIcon {
      color: white;
      background-color: $primary;
    }
    .ant-steps-item-tail::after {
      background-color: $primary;
    }
  }
}
.resultButtonCont {
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  .next {
    width: 45%;
  }
  @include for-phone-only() {
    display: block;
    .next {
      display: block;
      margin-bottom: 1rem;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.guestsContainer {
  .ageCountContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .ageCounter {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;

      .ageLabel {
        font-weight: 600;
        font-style: italic;
      }

      .counterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        .ageCount {
          font-size: 1.3rem;
        }
        .actionIcon {
          color: $primary;
          margin-right: 0.3rem;
          cursor: pointer;
          &.right {
            margin-left: 0.3rem;
          }
        }
      }
    }
  }

  .guestField {
    background-color: rgba(190, 190, 190, 0.15);
    padding: 0.2rem;
    margin-bottom: 0.7rem;
    .fieldheader {
      margin-bottom: 0.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 0.2rem;
      .removeCont {
        display: flex;
        align-items: center;
        .remove {
          color: red;
        }
        .bedIcon {
          font-size: 1.5rem;
          margin-right: 1rem;
        }
        .weelChair {
          margin-right: 0.5rem;
        }
      }

      .ageHeader {
        display: flex;
        align-items: center;
        .ageLabel {
          margin-left: 0.3rem;
          font-size: 1rem;
        }
        .ageIcon {
          font-size: 2rem;
          &.md {
            font-size: 1.4rem;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
    hr {
      width: 90%;
      margin-bottom: 1rem;
      opacity: 0.25;
    }
  }

  .childIcon {
    font-size: 4rem;
    margin-top: auto;
    &.md {
      font-size: 3rem;
    }
  }

  .guestChecks {
    display: flex;
    //justify-content: space-between;

    @include for-phone-only {
      flex-direction: column;
      align-items: start;
      height: 100%;
    }

    height: 1.2rem;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    .check {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      .infoCircle {
        color: $primary;
        font-size: 1.3rem;
        &.hide {
          display: none;
        }
      }
    }

    .form-input {
      margin-bottom: auto;
      &.check {
        // display: flex;
      }

      &.bed {
        justify-content: flex-end;
      }
    }
  }
}
.formItemLabel {
  .itemLabel {
    display: flex;
    .required {
      margin-right: 0.2rem;
      color: red;
    }
    .content {
      font-size: 1rem;
    }
  }
}
.ant-modal {
  @include for-phone-only() {
    top: 1rem;
  }
}
.ant-modal-body {
  @include for-phone-only() {
    padding: 0.8rem;
  }
}

.ant-form-item-label {
  label {
    font-size: 1.05rem;
  }
}

.modal{
  @include for-phone-only{
    width: 95% !important;

  }
}

.inputDesc {
  font-style: italic;
  padding: 0.1rem 0.7rem 0.1rem 0.7rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.guestContainer {
  display: none;
  &.show {
    display: block;
  }
}

.roomContainer {
  display: none;
  font-size: 1rem;
  .ant-radio-group {
    width: 100%;
    padding: 0.5rem;
  }
  .roomGenderItem {
    display: flex;
    justify-content: space-between;
    .ant-list-item {
      width: 100%;
    }

    .ant-radio-wrapper {
      display: flex;
      width: 100%;
      position: relative;
      .ant-radio {
        position: absolute;
        right: 0.3rem;
        width: 16px;
        input {
          &:focus {
            outline: none;
            border: 1px solid $primary;
            -webkit-box-shadow: 0px 0px 5px $primary;
            box-shadow: 0px 0px 5px $primary;
          }
        }
        .ant-radio-inner {
          border-color: $primary;
          &:after {
            background-color: $primary;
          }
        }
      }
      span {
        width: 100%;
      }
    }

    .roomGenderIcon {
      background-color: #bebebe;
      font-size: 2rem;
      padding: 0.3rem;
      color: white;
    }
  }
  &.show {
    display: block;
  }
}

.new-person-button {
  width: 100%;
  margin: 0.25rem 0;
}
.contact-form-button {
  width: 100%;
  background-color: $white;
  margin: 0.5rem 0;
  color: $text-color;
  border: $text-color 2px solid;
  &:hover {
    background-color: $primary;
    border: $primary 2px solid;
    color: $white;
    opacity: 0.85;
  }

  &:focus {
    background-color: darken($primary, 20%);
    border: darken($primary, 20%) 2px solid;
    color: $white;
    opacity: 0.85;
  }

  &:active {
    background-color: lighten($primary, 20%);
    border: lighten($primary, 20%) 2px solid;
    color: $white;
    opacity: 0.85;
  }
}

.aantalGasten {
  display: none;
}

.form-input {
  width: 100%;
  margin: 0 0 1rem 0;
  padding: 0 0.25rem;
  .extraButton {
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
  &.roomType {
    display: none;
  }
  .ant-form-item-label {
    padding: 0;
  }
  //hover over radio button
  .ant-radio-button-wrapper:hover {
    color: $primary;
    &:not(.ant-radio-button-wrapper-disabled) {
      &:before {
        background-color: $primary;
      }
      border-color: $primary;
    }
  }
  &:first-child {
    border-left-color: red;
  }
  .ant-radio-button-wrapper {
    &:not(:first-child) {
      background-color: transparent;
    }
  }
  //color selected radio button
  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: $primary;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &:before {
      background-color: $primary;
    }
    color: $white;
    border-color: $primary;
    background-color: $primary;
  }
}
