@import "../../../helpers/style/display-breakpoints";
@import "../../../helpers/style/variables";

.requestVisumCont {
  .guestID{
    display: none;
  }
  padding: 4rem;
  @include for-tablet-portrait-down {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: darken(white, 3%);

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: darken($primary, 3%);
    }
    .ant-steps-icon {
      color: darken($primary, 3%);
    }
    .ant-steps-item-tail {
      &:after {
        background-color: darken($primary, 3%);
      }
    }
  }

  .ant-row {
    display: flex;
    justify-content: space-between;

    .ant-form-item-label {
      width: 11rem;
      text-align: left;
    }
  }

  .ant-card-head-title {
    font-size: 1.5rem;
  }

  .ant-steps-item-title {
    font-size: 1.1rem;
  }

  .ant-steps-item-description {
    font-size: 1.1rem;
  }

  .requestVisumCard {
    background: white;
    border-radius: 0.5rem;
    width: 80%;
    @include for-tablet-portrait-down {
      width: 96%;
    }
  }

  .firstSlide {
    .firstSlideDesc {
      font-size: 1.1rem;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      .full-width {
        width: 49.5%;
      }

      @include for-tablet-portrait-down {
        display: block;
        .full-width {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .existsCard{
    font-size: 1.1rem;
    font-weight: 700;
    color: green;
  }
  .formCont {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    @include for-tablet-portrait-down {
      flex-direction: column;
    }

    .datePicker {
      width: 100%;
    }
    .firstColumn {
      height: 100%;
      width: 68%;
      box-sizing: border-box;
      margin-right: 1rem;

      @include for-tablet-portrait-down {
        width: 100%;
        margin-right: 0;
      }
    }
    .secondColumn {
      width: 30%;
      box-sizing: border-box;
      @include for-tablet-portrait-down {
        width: 100%;
        margin-bottom: 1rem;
      }
      .imgPreviewCont {
        position: relative;
        display: flex;
        flex-direction: column;
        .exmaple {
          position: absolute;
          background-color: black;
          color: white;
          border-radius: 0.5rem;
          padding: 0.3rem;
          top: 1rem;
          left: 1rem;
        }
        .passFoto {
          // width: 200px;
          // height: 200px;
          width: 100%;
          border-radius: 1rem;
          &.example {
            filter: blur(8px);
            -webkit-filter: blur(8px);
          }
        }
      }

      .uploadItem {
        .imgPreviewCont {
          //border-radius: .5rem;
        }

        .ant-form-item-row {
          display: block;
        }
      }
    }
  }
}
.smallText{
  font-size: .9rem;
}