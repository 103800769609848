@import url("https://fonts.googleapis.com/css?family=Catamaran:300,400,500i,900,900i");

.roomsPDF {
  position: relative;
  margin-top: 0;
  width: 793.3333333333333px;
  height: 1122.6666666666665px;
  padding-left: 3.75rem;
  padding-top: 3.75rem;
  padding-right: 3rem;
  //   background-color: #ffffff;
  background-size: cover;
  .col {
    margin-right: 4rem;
    width: 45%;

    &.logo {
      width: 30%;

      img {
        width: 100%;
      }
    }

    // &.text{
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
  .goldBar {
    width: 28.75rem;
    height: 0.75rem;
    background: linear-gradient(
        267deg,
        #e4a43c -7.46%,
        #f3d17a 47.09%,
        #f7eb8a 98.83%
      ),
      #d9d9d9;
  }

  h2 {
    margin-top: 0.7rem;
    color: #515151;
    font-family: Catamaran;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: 2.5rem; /* 125% */
  }

  .rooms {
    .columns {
      .roomContt {
        padding: 0.5rem;
        width: 90%;
        border-radius: 0.25rem;
        margin-bottom: .5rem;
        .roomHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          padding: 5px;
          background-color: rgba(255,255,255, 0.7);

        }
        .columns {
          display: flex;
          justify-content: space-between;
          padding: 0.7rem 1rem;
          background-color: rgba(211,211,211, 0.5);

          .item {
            font-weight:500;
            &.age{
              width: 20%;
              text-align: center;
            }
            &.bed{
              width: 20%;
              text-align: center;
            }
            &.weelchair{
              width: 20%;
              text-align: center;
            }
            &.name{
              width: 40%;
            }
          }
        }

        .rows{
          .row{
            display: flex;
            justify-content: space-between;
            padding: .5rem 1.2rem;
            background-color: rgba(238, 200, 98,.8);

            &.red{
              background-color: rgba(226, 190, 175, .8)

            }

            &.green{
              background-color: rgba(199, 216, 192, .8);
            }
            // margin-right: .2rem;

            .item {
              font-weight:500;
              

              &.age{
                width: 20%;
                text-align: center;
              }
              &.bed{
                width: 20%;
                text-align: center;
              }
              &.weelchair{
                width: 20%;
                text-align: center;
              }
              &.name{
                width: 40%;
              }
            }
          }

        
        }
      }
      .hideText {
        visibility: hidden;
      }
      .column {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        h2 {
          font-size: 1.75rem;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 0.4rem;
          padding: 2px;
        }
      }
    }
    .headerRooms {
      h3 {
        color: #515151;
        font-family: Catamaran;
        font-size: 2rem;
        font-style: normal;
        font-weight: 800;
        line-height: 2rem; /* 100% */
      }
    }

    .roomCont {
      width: 45%;
      .headerRoom {
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
        gap: 0.625rem;
        font-size: 0.9rem;
        align-self: stretch;
        background: #e2beaf;
        font-weight: 700;
        &.three {
          background: #c7d8c0;
        }
      }
      hr {
        margin: 0;
      }
      .roomGuest {
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
        font-size: 0.9rem;
        gap: 0.625rem;
        align-self: stretch;
        background: #f5f5f5;

        font-weight: 500;
      }
      .divider {
        height: 0.0625rem;
        align-self: stretch;
        background: rgba(216, 216, 216, 0.93);
        border: none;
      }
    }
  }
  .header {
    position: relative;
    .umrahText {
      // width: 22rem;
      width: 35%;
    }
    .gold {
      font-family: Catamaran;
      font-size: 6.25rem;
      font-style: normal;
      font-weight: 900;
      line-height: 6.25rem; /* 100% */
      background: linear-gradient(
        267deg,
        #e4a43c -7.46%,
        #f3d17a 47.09%,
        #f7eb8a 98.83%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h1 {
      color: #515151;
      font-family: Catamaran;
      font-size: 3rem;
      font-style: normal;
      font-weight: 900;
      line-height: 4rem; /* 100% */
      text-transform: uppercase;
      margin: 0;
    }

    h3 {
      color: #515151;
      font-family: Catamaran;
      font-size: 2.3rem;
      font-style: normal;
      font-weight: 800;
      line-height: 2.5rem; /* 100% */
    }
    .room {
      font-size: 2.4rem;
      font-weight: 500;
    }

    .logo {
      width: 20em;
      position: absolute;
      right: 8%;
      top: 55%;
    }
    margin-bottom: 6.25rem;
  }
}
