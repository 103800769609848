/**
It contains common device/screen breakpoints. Be careful updating these when devices change through the years.
Ref: https://medium.com/@catalinbridinel/this-makes-more-sense-than-the-counter-example-but-it-definitely-not-the-100-correct-way-7f4fb7e66c87
 **/

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) { @content; }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 899px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) { @content; }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 1199px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin for-desktop-height-small {
  @media (max-height: 700px) { @content; }
}

@mixin for-small-laptop {
  @media  (min-width: 1280px) and (max-width: 1550px) { @content; }
}
