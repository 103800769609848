@import '../../helpers/style/display-breakpoints';
@import '../../helpers/style/variables';

.cart-checkout-container{
  max-width: 90rem;
  margin: 9rem auto;
  padding: 0 1rem;
  .cart-checkout-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .checkout-details{
    display: flex;
    @include for-tablet-portrait-down{
      flex-direction: column;
    }
    .checkout-user-details{
      flex: 0 0 50%;
      .checkout-user-title{
        border-bottom: 1px solid $text-color
      }
    }
    .checkout-products-details{
      flex: 0 0 50%;
    }
  }
  .buttonAanbetaling{
    margin-top: 1rem;
  }
}