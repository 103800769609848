@import "../../helpers/style/variables";

.articles-categories-container{
  border-bottom: 2px solid $gray-color;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .articles-categories{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1rem;
    .category-button{
      padding: .25rem 1rem;
      font-weight: bold;
      margin: .5rem;
      background-color: #EEC86230;
      border-color: $primary;
      color: $primary;
    }
    .selected{
      background-color: $primary;
      color: $white;
    }
  }
}