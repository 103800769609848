@import "../../helpers/style/display-breakpoints";


.rowFlex{
    display: flex;
    @include for-phone-only(){
        display: block;
      }
    .ant-form-item{
        margin-right: 4rem;
    }
}

.productCont{
    display: flex;
    justify-content: space-between;
    @include for-phone-only(){
       flex-direction: column;
      }
}

.contact-form-button{
    width: 14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
