@import "../../helpers/style/variables";
@import "../../helpers/style/display-breakpoints";

.default-page-container{
  max-width: 90rem;
  margin: auto;
  padding: 3rem 1rem;
  @include for-tablet-portrait-down{
    text-align: center;
  }
}