@import "../../helpers/style/variables";

.arabia-about-container{
  max-width: 90rem;
  width: 100%;
  margin: auto;
  padding: 5rem 1rem;
  display: flex;
  justify-content: center;

  .arabia-about-label{
    text-transform: none;
    font-weight: 700;
    text-align: center;
  }
  .arabia-about-line{
    width: 7rem;
    border: #F1C965 2px solid;
  }
  .arabia-about-description{
    text-align: justify;
    max-width: 65rem;
    font-weight: 400;
  }
}